import React from 'react';

import Form from './Form';
import Icon from './Icon';

const fields = {
  name: {
    type: 'text',
    name: 'first_name',
    placeholder: 'Your name',
    isRequired: true,
  },
  email: {
    type: 'email',
    name: 'email',
    placeholder: 'Your email',
    isRequired: true,
  },
};

const url = `https://api.convertkit.com/v3/forms/${process.env.GATSBY_CONVERT_KIT_FORM_ID}/subscribe`;

export default () => (
  <div className="newsletter-container">
    <div className="newsletter content has-text-centered">
      <div>
        <h3 className="is-inline-block">Join the Newsletter</h3>
        <Icon className="ml-2" icon="envelope" size="lg" />
      </div>
      <p>Subscribe to get our latest articles by email</p>
      <Form
        url={url}
        fields={fields}
        extraPayload={{ api_key: process.env.GATSBY_CONVERT_KIT_API_KEY }}
        successMessage="Thank you! Please check your email to confirm your subscription."
      />
    </div>
  </div>
);
