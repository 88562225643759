import PropTypes from 'prop-types';
import React from 'react';

export const FieldType = {
  Text: 'text',
  Email: 'email',
  Submit: 'submit',
};

export const fieldShape = {
  type: PropTypes.oneOf(Object.values(FieldType)),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.boolean,
};

const Field = ({ type, name, placeholder, className, error, disabled, onChange, onClick }) => (
  <div className="field">
    <div className="control">
      <input
        className={`input ${className}`}
        type={type}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        onClick={onClick}
      />
    </div>
    <p className="help is-danger">{error}</p>
  </div>
);

Field.propTypes = {
  type: fieldShape.type,
  name: PropTypes.string,
  className: fieldShape.className,
  placeholder: fieldShape.placeholder,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

Field.defaultProps = {
  type: 'text',
  name: undefined,
  className: '',
  placeholder: '',
  error: '',
  disabled: false,
  onChange: () => {},
  onClick: () => {},
};

export default Field;
