import PropTypes from 'prop-types';
import React from 'react';

import { mapValues } from '../../utils';
import Spinner from '../Spinner';
import Field, { fieldShape } from './Field';
import { validateField } from './fieldValidators';

const checkNoErrorsExist = errors =>
  Object.values(errors).every(fieldErrors => fieldErrors.length === 0);

export default class Form extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      values: mapValues(props.fields, () => ''),
      errors: mapValues(props.fields, () => []),
      message: '',
      isLoading: false,
      hasBeenSubmitted: false,
    };
  }

  canSubmitForm = () => {
    const { hasBeenSubmitted, errors } = this.state;
    return !hasBeenSubmitted || checkNoErrorsExist(errors);
  };

  handleFieldChange = (key, value) => {
    const { hasBeenSubmitted } = this.state;
    const { values } = this.state;

    if (hasBeenSubmitted) {
      this.validateField(key, value);
    }
    this.setState({
      values: { ...values, [key]: value },
      message: '',
      isLoading: false,
      isSuccess: true,
    });
  };

  validateField = (key, value) => {
    const { fields } = this.props;
    const { errors } = this.state;

    const error = validateField(fields[key], value);
    this.setState({ errors: { ...errors, [key]: error } });
    return error;
  };

  validateExistingFields = () => {
    const { fields } = this.props;
    const { values } = this.state;

    const errors = mapValues(fields, (field, key) => validateField(field, values[key]));
    this.setState({ errors });
    return errors;
  };

  getSubmission = () => {
    const { fields } = this.props;
    const { values } = this.state;

    return Object.fromEntries(
      Object.entries(fields).map(([key, field]) => [field.name, values[key]]),
    );
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { hasBeenSubmitted } = this.state;

    const errors = this.validateExistingFields();
    if (!checkNoErrorsExist(errors)) {
      this.setState({ hasBeenSubmitted });
      return;
    }

    this.setState({ isLoading: true });
    try {
      const response = await this.submitData();
      if (response.ok) {
        this.handleSubmissionSuccess();
      } else {
        await this.handleResponseError(response);
      }
    } catch (error) {
      this.handleSubmissionError(error);
    }
  };

  submitData = async () => {
    const { url, extraPayload } = this.props;

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...extraPayload, ...this.getSubmission() }),
    });
  };

  handleResponseError = async response => {
    if (response.status === 400) {
      throw new Error(`Error: ${response.status}`);
    }

    const responseJson = await response.json();
    const { error, message } = responseJson;
    throw new Error(`${error}: ${message}`);
  };

  handleSubmissionSuccess = () => {
    const { successMessage } = this.props;

    this.setState({
      hasBeenSubmitted: true,
      isLoading: false,
      message: successMessage,
      isSuccess: true,
    });
  };

  handleSubmissionError = error => {
    this.setState({
      hasBeenSubmitted: true,
      isLoading: false,
      message: error.message,
      isSuccess: false,
    });
  };

  render() {
    const { fields } = this.props;
    const { message, errors, isLoading, isSuccess } = this.state;

    return (
      <form>
        <div className="field is-horizontal">
          <div className="field-body">
            {Object.keys(fields).map(key => (
              <Field
                key={key}
                {...fields[key]}
                disabled={isLoading}
                error={errors[key][0]}
                onChange={value => this.handleFieldChange(key, value)}
              />
            ))}
            <Field
              type="submit"
              className="button is-primary"
              disabled={isLoading || !this.canSubmitForm()}
              onClick={this.handleSubmit}
            />
          </div>
        </div>
        <p className={`subscription-message ${isSuccess ? 'has-text-primary' : 'has-text-danger'}`}>
          {isLoading ? <Spinner className="has-text-primary" /> : message}
        </p>
      </form>
    );
  }
}

Form.propTypes = {
  url: PropTypes.string.isRequired,
  fields: PropTypes.objectOf(PropTypes.shape(fieldShape)),
  extraPayload: PropTypes.objectOf(PropTypes.string),
  successMessage: PropTypes.string,
};

Form.defaultProps = {
  fields: {},
  extraPayload: {},
  successMessage: '',
};
