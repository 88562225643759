/* eslint-disable react/no-unescaped-entities */

import React from 'react';

import Layout from '../components/Layout';
import NewsletterForm from '../components/NewsletterForm';
import { filenameToSlug } from '../helpers';

export default () => (
  <Layout slug={filenameToSlug(__filename)}>
    <NewsletterForm />
  </Layout>
);
