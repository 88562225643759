import { FieldType } from './Field';

const validateEmail = value =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ? '' : 'Invalid email';

const validateRequired = value => (value.trim() === '' ? 'Required field' : '');

const defaultFieldValidators = {
  [FieldType.EMAIL]: [validateEmail],
};

export const validateField = (field, value) => {
  const { type, isRequired } = field;

  const validators = new Set(defaultFieldValidators[type]);
  if (isRequired) {
    validators.add(validateRequired);
  }

  return [...validators].map(validateValue => validateValue(value)).filter(e => e);
};
